import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Layout from '../../../components/Layout'
import HeaderSEO from '../../../components/partials/HeaderSEO'
import Spacing from '../../../components/partials/Spacing'
import { StaticImage } from 'gatsby-plugin-image'
import EventRegistrationForm from '../../../components/EventRegistrationForm'

const EventRegistrationChicago = () => {
    const data = useStaticQuery(graphql`
        query eventRegistrationChicagoQuery {
            allContentfulPages(
                filter: { pageReference: { pageIdentifier: { eq: "EventRegistrationChicago" } } }
            ) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Layout>
            <HeaderSEO pageSEO={pageSEO} />
            <Spacing />
            <div style={{ position: 'relative', display: 'flex', flexWrap: 'wrap' }}>
                <div style={{ width: '100%' }}>
                    <section
                        className='EventRegistration--heroChicago'
                        style={{ position: 'relative' }}>
                        <div
                            className='container-1 EventRegistration--hero__content'
                            style={{ width: '90%' }}>
                            <h1 className='cera-pro'>
                                Apache Kafka and Flink for <br className={'onlyDesktop'} /> data
                                streaming at scale
                            </h1>
                            <h2 className='cera-pro'>Featuring Arity and AWS</h2>
                            <h3 className='roboto EventRegistration--hero__h3-primary'>
                                Wednesday 9th October | 5-8pm
                            </h3>
                            <h3 className='roboto EventRegistration--hero__h3-secondary'>
                                222 W Merchandise Mart Plaza, <br /> Chicago, IL 60654, 8th floor
                            </h3>
                        </div>
                    </section>

                    <section className='EventRegistration--under-hero'>
                        <div className='container-1'>
                            <p>
                                Dive into the world of data streaming with stories and best
                                practices from technology pioneers. This meetup combines practical
                                enterprise applications with cutting-edge cloud solutions to make
                                Kafka and Flink workloads easier, offering valuable knowledge for
                                Kafka professionals to make the most of their streaming
                                infrastructure.
                            </p>
                        </div>
                    </section>
                    <Spacing />
                    <div className='onlyMobile'>
                        <div className='EventRegistration--form'>
                            <EventRegistrationForm action='https://go.pardot.com/l/877242/2024-09-23/t1qnkx' />
                        </div>
                        <Spacing />
                    </div>
                    <section className='roboto EventRegistration--feature'>
                        <div className='container-1'>
                            <h4 className='EventRegistration--feature__company cera-pro'>Arity</h4>
                            <h1 className='EventRegistration--feature__title cera-pro'>
                                Reliable data streaming at scale with{' '}
                                <br className={'onlyDesktop'} /> Kafka and Lenses
                            </h1>
                            <p>
                                Arity makes transportation smarter and safer by collecting and
                                analyzing enormous amounts of data and predictive analytics. Apache
                                Kafka is used with Lenses to enable reliable data streaming. In this
                                meetup, Arity will walk you through the journey of their usage of
                                Kafka, and the use cases where Lenses crucial for their business.
                            </p>
                            <p>
                                <span className='EventRegistration--feature__speaker'>
                                    <i
                                        className='fab fa-linkedin f-19 p-0 p-0'
                                        style={{ marginRight: '3px' }}
                                        aria-hidden='true'></i>
                                    <a
                                        rel={'noreferrer'}
                                        href='https://www.linkedin.com/in/phil-falkenholm-2751572/'
                                        target={'_blank'}>
                                        Speaker:
                                    </a>
                                </span>{' '}
                                Phil Falkenholm - Director of Cloud Infrastructure & Security at
                                Arity
                            </p>
                        </div>
                    </section>
                    <Spacing />
                    <section className='container-1 roboto EventRegistration--feature'>
                        <h4 className='EventRegistration--feature__company cera-pro'>AWS</h4>
                        <h1 className='EventRegistration--feature__title cera-pro'>
                            Making Kafka and Flink management <br className={'onlyDesktop'} />{' '}
                            easier
                        </h1>
                        <p>
                            Managing your own streaming workloads can be challenging. Join AWS and
                            Lenses.io to learn more about how managed services can help make running
                            Apache Kafka and Apache Flink workloads easier. See a live demo
                            featuring both Amazon Managed Streaming for Apache Kafka (MSK) and
                            Amazon Managed Service for Apache Flink (MSF). Hear from experts in the
                            streaming industry about recent releases of MSK, MSF, and more!
                        </p>
                        <p>
                            <span className='EventRegistration--feature__speaker'>
                                <i
                                    className='fab fa-linkedin f-19 p-0 p-0'
                                    style={{ marginRight: '3px' }}
                                    aria-hidden='true'></i>
                                <a
                                    rel={'noreferrer'}
                                    href='https://www.linkedin.com/in/jeremyber/'
                                    target={'_blank'}>
                                    Speaker:
                                </a>
                            </span>{' '}
                            Jeremy Ber - Streaming Specialist Solutions Architect at Amazon Web
                            Services (AWS)
                        </p>
                    </section>
                    <section className='container-1' style={{ marginTop: '40px' }}>
                        <iframe
                            src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21699047.16966569!2d-65.46890909632066!3d46.582884540024175!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2cb6fa650467%3A0xd94994faa9dd5715!2s222%20W%20Merchandise%20Mart%20Plaza%2C%20Chicago%2C%20IL%2060654%2C%20USA!5e0!3m2!1sen!2scz!4v1727208047005!5m2!1sen!2scz'
                            width='600'
                            height='450'
                            style={{ border: 0 }}
                            allowFullScreen
                            loading='lazy'
                            referrerPolicy='no-referrer-when-downgrade'></iframe>
                    </section>
                </div>
                <div style={{ position: 'sticky', right: '20px' }} className='onlyDesktop'>
                    <div className='EventRegistration--form'>
                        <EventRegistrationForm action='https://go.pardot.com/l/877242/2024-09-23/t1qnkx' />
                    </div>
                </div>
            </div>

            <Spacing />
            <section className={' mt-5 mt-sm-0'}>
                <div className='sectionWithGuyBlackStrip' style={{ paddingBottom: '50px' }}>
                    <div className='d-flex flex-wrap align-items-bottom'>
                        <div
                            className='container-1 col-12 text-left mt-3 mb-4'
                            style={{ maxHeight: '170px', paddingLeft: 0 }}>
                            <h2 className='f-40 lh-52 pb-0 text-white'>
                                Build better, sleep easy <br className={'onlyDesktop'} />
                                with the developer experience <br className={'onlyDesktop'} /> for
                                any Kafka
                            </h2>
                            <div className='roll-button navbar-dl-button heroLottieButton'>
                                <a
                                    id='navbar-download'
                                    style={{ fontSize: '1rem' }}
                                    className='text-white mr-0 bg-red'
                                    href='/start/'>
                                    <span data-hover='Start free'>Start free</span>
                                </a>
                            </div>
                        </div>
                        <StaticImage
                            className='pt-2 w-35 sectionWithGuyImage'
                            style={{ left: '50%' }}
                            src='../resources/images/new_homepage/developer-experience.webp'
                            placeholder='tracedSVG'
                            alt='Security'
                        />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default EventRegistrationChicago

import React, { useState } from 'react'
import * as CompanyEmailValidator from 'company-email-validator'

// eslint-disable-next-line react/prop-types
const EventRegistrationForm = ({ action }) => {
    const options = [{id: 1, option: 'I’m new to Kafka' }, { id: 1, option: 'I’m using Kafka in development' }, { id: 1, option: 'I’m using Kafka in production' }]

    const [disabledButton] = useState(false)
    const [selectedOption, setSelectedOption] = useState(
        options[0]
    ) // Set the initial selected option here

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value) // Update the selected option when the user changes the selection
    }

    const validateEmail = () => {
        const email = document.querySelector('#email')
        if (!CompanyEmailValidator.isCompanyEmail(email.value)) {
            email.classList.add('is-invalid')
            document.querySelector('#formsubmit').disabled = true
        } else {
            email.classList.remove('is-invalid')
            document.querySelector('#formsubmit').disabled = false
        }
    }


    return (
        <form
            id='enterprise-form'
            action={action}
            acceptCharset='UTF-8'
            method='POST'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        style={{ marginRight: '20px' }}
                        type='text'
                        required
                        id='first_name'
                        maxLength={80}
                        name='first_name'
                        placeholder='First Name*'
                    />
                    <input
                        className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                        type='text'
                        required
                        id='last_name'
                        maxLength={80}
                        name='last_name'
                        placeholder='Last Name*'
                    />
            </div>
            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='email'
                required
                id='email'
                maxLength={100}
                name='email'
                placeholder='Email*'
                onChange={validateEmail}
            />
            {validateEmail && (
                <div className='invalid-feedback ml-2'>Please provide your professional email</div>
            )}

            <input
                className='footer-color w-100 pl-2 input-form-border pb-2 placeholder-opacity my-2'
                type='text'
                required
                maxLength={100}
                id='company'
                name='company'
                placeholder='Company'
            />

            <span style={{display: 'block', color: '#495057', opacity: 0.78, padding: '5px 0'}} className='pl-2'>Where are you on your Kafka journey?</span>
                <select
                    className='my-1 bg-white countries-dropdown'
                    name='comments'
                    id='comments'
                    value={selectedOption}
                    onChange={handleSelectChange}
                    style={{ width: '100%', color: '#495057' }}>
                    {options.map((option) => (
                        <option key={option.id} value={option.option}>
                            {option.option}
                        </option>
                    ))}
                </select>

            <div className='m-2 mb-4'>
                <label className='d-none' htmlFor='Terms Of Use'>
                    {' '}
                    Terms of use and Privacy Policy
                </label>
                <input type='checkbox' id='terms_of_use' name='Terms Of Use' required />{' '}
                <span className='pl-1'>
                    By submitting the form, you agree to our{' '}
                    <a className='link-text' href='/legals/terms-and-conditions/'>
                        Terms of Use{' '}
                    </a>
                    ,{' '}
                    <a className='link-text' href='/legals/policy/'>
                        Privacy Policy
                    </a>{' '}
                    and{' '}
                    <a className='link-text' href='/legals/sela/'>
                        SELA
                    </a>
                </span>
            </div>
            <div
                className='g-recaptcha'
                data-sitekey='6Ldy0-UZAAAAAJT6bzr6y5NXI9lQ7XJ14O3o6tjF'
                data-size='invisible'></div>
            <div style={{textAlign: 'center'}}>
                <input
                    className={`${
                        disabledButton && 'mt-5'
                    } bg-red text-white mx-auto br-21 monitoring-guide-cta-padding border-0`}
                    id={'formsubmit'}
                    style={{ fontSize: '1rem', backgroundColor: '#ff9448' }}
                    type='submit'
                    value={'Register Now'}
                    disabled={disabledButton}></input>
            </div>
        </form>
    )
}

export default EventRegistrationForm
